import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CadastroService } from 'src/app/services/cadastro.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-meu-perfil',
  templateUrl: './meu-perfil.component.html',
  styleUrls: ['./meu-perfil.component.scss']
})
export class MeuPerfilComponent implements OnInit {
  dadosIntegracao: string;
  dadosContribuinte: string;
  contribuinteFormatado: any;
  dadosFormatado: any;
  cadastroEmUso: any;
  cadastroFormatado: any;
  contribuinteId: any;
  emailsTemp: any;
  emailsApi: any;
  emailsApagar: any;
  dadoAlterado: boolean;
  emails: any;
  emailsDeletados: any = [];
  codCadastroContribuinte: string;
  dadosCadastro: any;
  userForm: FormGroup;
  primarioBloqueado: boolean = false;
  perfisAcesso: Array<any>;
  bloqueiosAcesso: Array<any> = [];
  loading: boolean = true;
  modalAtivo: boolean = false;
  
  constructor(
    private cadastroService: CadastroService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.dadosIntegracao = localStorage.getItem('dadosIntegracao');
    this.dadosContribuinte = localStorage.getItem('contribuinteEmUso');
    this.contribuinteFormatado = JSON.parse(this.dadosContribuinte);
    this.dadosFormatado = JSON.parse(this.dadosIntegracao);
    this.cadastroEmUso = localStorage.getItem('cadastroEmUso');
    this.cadastroFormatado = JSON.parse(this.cadastroEmUso);
    if (this.cadastroEmUso) {
      this.codCadastroContribuinte = this.cadastroFormatado.codigo;
    } else {
      this.contribuinteId = this.contribuinteFormatado.codigo;
      this.codCadastroContribuinte = this.contribuinteFormatado.cadastro.codigo;
    }
    this.criarFormulario();
    this.getUsuario();
  }

  criarFormulario() {
    this.userForm = this.formBuilder.group({
      tipoPessoa: [''],
      cpfCnpj: [''],
      dscNomeCadastro: [''],
      dataRegistro: [''],
      origemCadastro: [''],
      email: [''],
      prioridadeEmail: [''],
      bloqueiosAcesso: ['']
    });
  }
  
  getUsuario() {
    this.cadastroService.getUsuario(this.codCadastroContribuinte).subscribe(res => {
      this.dadosCadastro = res;
    }, error => {
      Swal.fire({
        icon: 'error',
        text: 'Ocorreu um erro ao carregar os dados do usuário.'
      })
    }, () => {
      this.getPerfis();
      this.userForm.patchValue({
        tipoPessoa: this.dadosCadastro.tipoPessoa,
        cpfCnpj: this.dadosCadastro.cpfCnpj,
        dscNomeCadastro: this.dadosCadastro.dscNomeCadastro,
        dataRegistro: this.datePipe.transform(this.dadosCadastro.calendario.date, 'dd/MM/yyyy'),
        origemCadastro: this.dadosCadastro.origemCadastro.dscOrigemCadastro,
        bloqueiosAcesso: 
          this.dadosCadastro.bloqueiosAcesso[0]?.situacao 
            ? this.dadosCadastro.bloqueiosAcesso[0]?.situacao 
            : 0
      });

      this.emails = this.dadosCadastro.emails;

      this.ordenarEmails();
      this.userForm.updateValueAndValidity();
      this.userForm.disable();
      this.userForm.controls.email.enable();
      this.userForm.controls.prioridadeEmail.enable();

      this.bloqueiosAcesso = this.dadosCadastro.bloqueiosAcesso;
    });
  }

  getPerfis() {
    this.cadastroService.getPerfis().subscribe((res: Array<any>) => {
      this.perfisAcesso = res;
    }, (error) => {

    }, () => {
      const perfilAcessoUsuario = this.dadosCadastro.cadastroPerfilAcesso;
      perfilAcessoUsuario.forEach(res => {
        const indexPerfil = this.perfisAcesso.findIndex(perfil => perfil.codigo == res.seqPerfilAcesso);
        if(indexPerfil !== -1 && res.situacao == 1) {
          this.perfisAcesso[indexPerfil].checked = true;
        }
      });

      this.loading = false;
    });
  } 

  ordenarEmails() {
    if(this.emails.findIndex(email => email.tipoEmail == 1) !== -1) {
      this.primarioBloqueado = true;
    } else {
      this.primarioBloqueado = false;
    }

    this.emails.sort((a, b) => {
      const descricaoA = a.descricao.toLowerCase();
      const descricaoB = b.descricao.toLowerCase();
      if (a.tipoEmail === 1 && b.tipoEmail === 1 || a.tipoEmail !== 1 && b.tipoEmail !== 1) {
        return descricaoA.localeCompare(descricaoB);
      }
      return a.tipoEmail === 1 ? -1 : 1;
    });
  }

  adicionarEmail() {
    if (this.userForm.controls.email.value && this.userForm.controls.prioridadeEmail.value) {
      const email = {
        descricao: this.userForm.controls.email.value,
        situacao: 1,
        tipoEmail: this.userForm.controls.prioridadeEmail.value
      }

      this.emails.push(email);
      this.ordenarEmails();

      this.userForm.controls.prioridadeEmail.reset();
      this.userForm.controls.email.reset();
    } else {
      Swal.fire({
        text: 'Digite o email e/ou selecione a prioridade',
        icon: 'warning'
      })
    }
  }

  apagarEmail(index, email) {
    if (email.codigo) {
      let obj = {
        descricao: email.descricao,
        situacao: 0,
        tipoEmail: email.tipoEmail,
        codigo: email.codigo
      }
      this.emailsDeletados.push(obj);
    }
    this.emails.splice(index, 1)[0] as any;
    this.ordenarEmails();
  }

  desistir() {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção!',
      text: 'Todos os dados digitados serão descartados. Confirmar desistir??',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não'
    }).then(result => {
      if (result.isConfirmed) {
        this.router.navigate(['inicio']);
      } else if (result.isDenied) {
        Swal.close();
      }
    })
  }

  salvarDados() {
    this.loading = true;
    if (!this.primarioBloqueado) {
      Swal.fire({
        icon: 'warning',
        text: 'É necessário pelo menos um E-mail Primário'
      })
      return;
    }

    if(this.userForm.valid && this.emails.length > 0) {
      let form;
      form = this.userForm.getRawValue();

      let obj = {
        cpfCnpj: this.dadosCadastro.cpfCnpj,
        dscNomeCadastro: this.dadosCadastro.dscNomeCadastro,
        tipoPessoa: this.dadosCadastro.tipoPessoa,
        emails: null,
        cadastroPerfilAcesso: this.dadosCadastro.cadastroPerfilAcesso,
        origemCadastro: {
          codOrigemCadastro: this.dadosCadastro.origemCadastro.codOrigemCadastro,
          dscOrigemCadastro: this.dadosCadastro.origemCadastro.dscOrigemCadastro,
        }
      };

      let emails = [];
      for (let i = 0; i < this.emails.length; i++) {
        emails[i] = {
          descricao: this.emails[i].descricao,
          situacao: 1,
          tipoEmail: this.emails[i].tipoEmail,
          codigo: this.emails[i].codigo ? this.emails[i].codigo : null
        }
      }
      obj.emails = emails;

      for (let i = 0; i < this.emailsDeletados.length; i++) {
        obj.emails.push(this.emailsDeletados[i]);
      }

      this.cadastroService.editarUsuario(obj).subscribe(
        res => {
          Swal.fire({
            text: 'Alterações aplicadas com sucesso.',
            icon: 'success'
          });
          
        },
        err => {
          Swal.fire({
            icon: 'error',
            text: 'Ocorreu um erro ao editar o cadastro.'
          })
        },
        () => {
          this.loading = false;
          this.router.navigate(['/inicio']);
        }
      )
    }
  }
}
