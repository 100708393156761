import { Component, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { InicioComponent } from "../../inicio/inicio.component";
import { nfaService } from "src/app/services/nfa.service";

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})

export class SidemenuComponent implements OnInit{
  @ViewChild('inicioComponent') inicioComponent: InicioComponent;
  sidemenuStorage: any;
  sidemenu: any = 'false';
  logged: boolean = false;
  nivelUsuario: string;
  loading: boolean = false;
  contribuinteEmUso: string;
  contribuinteFormatado: any;
  cadastroEmUso: any;
  objTermoAdesao: any;
  termoAdesao: any;
  registroTermoAdesao: boolean = false;
  backdrop: boolean = false;
  modalAtivo: boolean = false;
  dadosUsuario: any;
  objUsuario: any;
  codigoContribuinte: string = '';
  dadosFormatados: any;
  dadosIntegracao: any;

  constructor(
    private router: Router,
    private abrirFecharNfa: nfaService
  ) {
    this.router.events.subscribe((ev) => {
      if(ev instanceof NavigationEnd) {

        if(ev.url == '/inicio') {
          this.logged = true;
        } else if (ev.url == '/refis') {
          this.logged = true;
        } else if (ev.url =='/caixa-postal') {
          this.logged = true;
        } else {
          this.logged = false;
        }
      }
    })
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.nivelUsuario = localStorage.getItem('nivelUsuario');
      this.contribuinteEmUso = localStorage.getItem('contribuinteEmUso');
      this.contribuinteFormatado = JSON.parse(this.contribuinteEmUso);
      this.dadosIntegracao = localStorage.getItem('dadosIntegracao');
      this.dadosFormatados = JSON.parse(this.dadosIntegracao);
      this.cadastroEmUso = localStorage.getItem('cadastroEmUso');      
    }, 500);
  }

  abrirSidemenu() {
    console.log("passei");
    localStorage.setItem('sidemenu', 'true');
    this.sidemenu = localStorage.getItem('sidemenu');
    console.log(this.sidemenu);
  }

  fecharSidemenu() {
    this.sidemenu = 'false';
    localStorage.setItem('sidemenu', 'false');
  }

  logout() {
    localStorage.removeItem('sidemenu');
    localStorage.removeItem('dadosIntegracao');
    localStorage.removeItem('contribuinteEmUso');
    localStorage.removeItem('cadastroEmUso');
    localStorage.removeItem('token');
    localStorage.removeItem('primeiroAcesso');
    this.sidemenu = 'false';
    window.location.href = "https://sso.acesso.gov.br/logout";
    window.location.href = "https://dfe.sefaz.to.gov.br";
    // this.logoutService.logout().subscribe();
  }
  
  abrirModal(){
    if (this.modalAtivo == true) {
      this.modalAtivo = false;
    } else {
      this.modalAtivo = true;
    }
  }

  abrirNegociacaoDebito(){
    this.dadosUsuario = localStorage.getItem('contribuinteEmUso');
    this.objUsuario = JSON.parse(this.dadosUsuario);
    this.codigoContribuinte = this.objUsuario.cadastro.cpfCnpj;
    const codigoContribuinteMock = 'credor=60&tp=7&d=' + this.codigoContribuinte;
    const base64 = btoa(codigoContribuinteMock);
    const externalUrl = 'https://portaltocantins.giexonline.com.br/Start.aspx?dec=' + base64;
    window.open(externalUrl, '_blank');
    this.modalAtivo = false;
  }

  abrirPortalAcompanhamento() {
    this.dadosUsuario = localStorage.getItem('contribuinteEmUso');
    this.objUsuario = JSON.parse(this.dadosUsuario);
    this.codigoContribuinte = this.objUsuario.cadastro.cpfCnpj;
    const codigoContribuinte = 'credor=60&tp=3&d=' + this.codigoContribuinte;
    const base64 = btoa(codigoContribuinte);
    const externalUrl = ' https://portaltocantins.giexonline.com.br/Start.aspx?dec=' + base64;
    window.open(externalUrl, '_blank');
  }

  abrirRefisIpva(){
    const externalUrl = 'https://ipva.sefaz.to.gov.br/como_pagar_ipva.php';
    window.open(externalUrl, '_blank');
  }

  abrirModalNfa() {
    this.abrirFecharNfa.abrirFecharNfa();
    
  }
}
