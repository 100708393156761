import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from "src/environments/environment";
import { ResponseApi } from "../features/common/interface/responseApi";

@Injectable({
  providedIn: 'root'
})
export class CaixaPostalService {

  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient) { }

  getComunicados(objUsuario: any, searchParams: any): Observable<any> {
    const params = this.setParams(searchParams);
    return this.http.get<ResponseApi>(`${this.apiUrl}/caixa-postal/listarComunicadosDfe/${objUsuario}/`, {params});
  }

  getComunicado(codigoCadastro: number, idComunicado: number): Observable<ResponseApi> {
    return this.http.get<ResponseApi>(`${this.apiUrl}/caixa-postal/listarComunicadosComResposta/${codigoCadastro}/${idComunicado}`)
  }

  getAnexos(path: any): Observable<ResponseApi> {
    return this.http.post<ResponseApi>(`${this.apiUrl}/caixa-postal/obterAnexo`, path);
  }

  gerarLeitura(seqCaixaPostal: any, seqCadastro: any): Observable<string> {
    return this.http.get<string>(`${this.apiUrl}/caixa-postal/gerarLeitura/${seqCaixaPostal}/${seqCadastro}`, { responseType: 'text' as 'json' });
  }

  respostaComunicado(obj): Observable<ResponseApi> {
    return this.http.post<ResponseApi>(`${this.apiUrl}/caixa-postal/responderComunicado`, obj);
  }

  getTiposComunicados(): Observable<any> {
    return this.http.get<ResponseApi>(`${this.apiUrl}/caixa-postal/listarTiposComunicados`);
  }

  enviarComunicado(obj): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/caixa-postal/criarComunicado`, obj);
  }

  getNomeacoes(id) {
    return this.http.get<ResponseApi>(`${this.apiUrl}/caixa-postal/nomearResponsavelCaixaPostal/listar/${id}`);
  }

  validarCpf(cpf) {
    return this.http.get<ResponseApi>(`${this.apiUrl}/caixa-postal/nomearResponsavelCaixaPostal/validarCpf/${cpf}`);
  }

  nomearResponsavel(obj) {
    return this.http.post<ResponseApi>(`${this.apiUrl}/caixa-postal/nomearResponsavelCaixaPostal`, obj);
  }

  getListaTipoComunicado(): Observable<any> {
    return this.http.get(`${this.apiUrl}/caixa-postal/tipoComunicado/listarResumido`);
  }

  setParams(searchParams) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }


}
