<section class="margem-top container">
  <div class="wizard-sample-1">
    <div class="br-wizard">
      <div class="wizard-progress">
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Solicitação de Alteração"
          [attr.active]="active1 == true ? 'active' : null" 
          [attr.disabled]="active1 == false ? 'disabled' : null" 
          step="1">
          <span class="info">Solicitação de Alteração</span>
        </button>
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Seleção do Produtor Rural"
          [attr.active]="active2 == true ? 'active' : null"
          [attr.disabled]="active2 == false ? 'disabled' : null"
          step='2'>
          <span class="info">Seleção do Produtor Rural</span>
        </button>
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Identificação do Produtor Rural" 
          [attr.active]="active3 == true ? 'active' : null"
          [attr.disabled]="active3 == false ? 'disabled' : null"
          step='3'>
          <span class="info">Identificação do Produtor Rural</span>
        </button>
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Endereço da Propriedade Rural" 
          [attr.active]="active4 == true ? 'active' : null"
          [attr.disabled]="active4 == false ? 'disabled' : null"
          step='4'>
          <span class="info">Endereço da Propriedade Rural</span>
        </button>
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Endereço de Correspondência/Fiscal" 
          [attr.active]="active5 == true ? 'active' : null"
          [attr.disabled]="active5 == false ? 'disabled' : null"
          step='5'>
          <span class="info">Endereço de Correspondência/ Fiscal</span>
        </button>
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Informações Econômicas e Fiscais" 
          [attr.active]="active6 == true ? 'active' : null"
          [attr.disabled]="active6 == false ? 'disabled' : null"
          step='6'>
          <span class="info">Informações Econômicas e Fiscais</span>
        </button>
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Informações sobre a exploração rural" 
          [attr.active]="active7 == true ? 'active' : null"
          [attr.disabled]="active7 == false ? 'disabled' : null"
          step='7'>
          <span class="info">Informações sobre a exploração rural</span>
        </button>
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Contabilista Responsável" 
          [attr.active]="active8 == true ? 'active' : null"
          [attr.disabled]="active8 == false ? 'disabled' : null"
          step='8'>
          <span class="info">Contabilista Responsável</span>
        </button>
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Sócios" 
          [attr.active]="active9 == true ? 'active' : null"
          [attr.disabled]="active9 == false ? 'disabled' : null"
          step='9'>
          <span class="info">Sócios</span>
        </button>
        <button 
          class="wizard-progress-btn wizard-font-s" 
          type="button" 
          title="Confirmação" 
          [attr.active]="active10 == true ? 'active' : null"
          [attr.disabled]="active10 == false ? 'disabled' : null"
          step='10'>
          <span class="info">Confirmação</span>
        </button>
      </div>
    </div>
  </div>
  <app-alteracao-produtor-etapa-1
    *ngIf="active1 == true"
    [contribuinte]="contribuinteFormatado"
    [listaBic]="listaBic"
    (passarEtapa)="passarEtapa(1, $event)"
    (sair)="desistir()"
  >
  </app-alteracao-produtor-etapa-1>
  <app-alteracao-produtor-etapa-2
    *ngIf="active2 == true"
    [contribuintes]="dadosFormatados"
    (getCodigoContribuinte)="pegarCodigoContribuinte($event)"
    (voltarEtapa)="voltarEtapa(2)"
    (sair)="desistir()"
  >
  </app-alteracao-produtor-etapa-2>
  <app-alteracao-produtor-etapa-3
    *ngIf="active3 == true"
    [produtorRural]="dadosProdutorRural"
    [dadosEditados]="dadosEditados"
    (passarEtapa)="passarEtapa(3)"
    (voltarEtapa)="voltarEtapa(3)"
    (sair)="desistir()"
  >
  </app-alteracao-produtor-etapa-3>
  <app-alteracao-produtor-etapa-4
    *ngIf="active4 == true"
    [produtorRural]="dadosProdutorRural"
    [listaComplemento]="listaComplemento"
    [dadosEditados]="dadosEditados"
    (passarEtapa)="passarEtapa(4)"
    (voltarEtapa)="voltarEtapa(4)"
    (sair)="desistir()"
  >
  </app-alteracao-produtor-etapa-4>
  <app-alteracao-produtor-etapa-5
    *ngIf="active5 == true"
    [produtorRural]="dadosProdutorRural"
    [dadosEditados]="dadosEditados"
    [listaComplemento]="listaComplemento"
    (passarEtapa)="passarEtapa(5)"
    (voltarEtapa)="voltarEtapa(5)"
    (sair)="desistir()"
  >
  </app-alteracao-produtor-etapa-5>
  <app-alteracao-produtor-etapa-6
    *ngIf="active6 == true"
    [produtorRural]="dadosProdutorRural"
    [dadosEditados]="dadosEditados"
    [listaClassificacao]="listaClassificacao"
    [listaRegimeRecolhimento]="listaRegimeRecolhimento"
    [listaNaturezaJuridica]="listaNaturezaJuridica"
    [listaCnae]="listaCnae"
    (passarEtapa)="passarEtapa(6)"
    (voltarEtapa)="voltarEtapa(6)"
    (sair)="desistir()"
  >  
  </app-alteracao-produtor-etapa-6>
  <app-alteracao-produtor-etapa-7
  *ngIf="active7 == true"
  [produtorRural]="dadosProdutorRural"
  [listaOcupacao]="listaOcupacao"
  [dadosEditados]="dadosEditados"
  (passarEtapa)="passarEtapa(7)"
  (voltarEtapa)="voltarEtapa(7)"
  (sair)="desistir()"
  >  
  </app-alteracao-produtor-etapa-7>
  <app-alteracao-produtor-etapa-8
  *ngIf="active8 == true"
  [produtorRural]="dadosProdutorRural"
  [dadosEditados]="dadosEditados"
  (passarEtapa)="passarEtapa(8)"
  (voltarEtapa)="voltarEtapa(8)"
  (sair)="desistir()"
  >  
  </app-alteracao-produtor-etapa-8>
  <app-alteracao-produtor-etapa-9
  *ngIf="active9 == true"
  [produtorRural]="dadosProdutorRural"
  [listaPaises]="listaPaises"
  [listaGed]="listaGed"
  [dadosEditados]="dadosEditados"
  (passarEtapa)="passarEtapa(9)"
  (voltarEtapa)="voltarEtapa(9)"
  (sair)="desistir()"
  >  
  </app-alteracao-produtor-etapa-9>
  <app-alteracao-produtor-etapa-10
  *ngIf="active10 == true"
  [produtorRural]="dadosProdutorRural"
  [dadosEditados]="dadosEditados"
  [dadosSolicitante]="dadosSolicitante"
  [listaComplemento]="listaComplemento"
  [listaOcupacao]="listaOcupacao"
  [listaPaises]="listaPaises"
  [listaGed]="listaGed"
  (voltarEtapa)="voltarEtapa(10)"
  (sair)="desistir()"
  >  
  </app-alteracao-produtor-etapa-10>
</section>

<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>