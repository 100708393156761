import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild, enableProdMode } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { catchError, timeout } from 'rxjs/operators';

import { ProcuracaoService } from "src/app/services/procuracao.service";
import Swal from "sweetalert2";
import * as dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { DatePickerComponent, IDatePickerConfig } from "ng2-date-picker";
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { ContribuinteService } from "src/app/services/contribuinte.service";
import { ProdutorRuralService } from "src/app/services/produtor-rural.service";
import { CadastroProdutorRural } from "../../../../common/interface/cadastroProdutorRural";
import { UsuarioLogadoService } from "src/app/services/usuarioLogado.service";


@Component({
  selector: 'app-cadastramento-produtor-rural',
  templateUrl: './cadastramento.component.html',
  styleUrls: ['./cadastramento.component.scss']
})
export class CadastramentoProdutorRuralComponent implements OnInit {

  @ViewChild('datePicker') datePicker: DatePickerComponent;

  @ViewChild('cepEnderecoPropriedade') cepEnderecoPropriedade: ElementRef;

  @ViewChild('cepEnderecoCorrespondencia') cepEnderecoCorrespondencia: ElementRef;

  @ViewChild ('cepSocios') cepSocios: ElementRef;
  
  @ViewChild('tipoPessoaSocio') tipoPessoaSocio: ElementRef;

  public latitudeLongitude = { '0' : { pattern: new RegExp('[0-9\-\,]') } }

  carregado: boolean;
  contribuinteEmUso: string;
  procuracoes: any;
  situacao: any;
  dado: any;
  dados: any[] = [];
  data: any;
  active1: boolean = true;
  active2: boolean = false;
  active3: boolean = false;
  active4: boolean = false;
  active5: boolean = false;
  active6: boolean = false;
  active7: boolean = false;
  active8: boolean = false;
  active9: boolean = false;
  produtorRuralForm: FormGroup;
  enderecoPropriedadeForm: FormGroup;
  enderecoCorrespondenciaForm: FormGroup;
  informacoesEconomicasForm: FormGroup;
  cnaeForm: FormGroup;
  informacoesExploracaoForm: FormGroup;
  contabilistaForm: FormGroup;
  sociosForm: FormGroup;
  usuarioLogado: any;
  passo: number = 1;
  solicitante: string;
  cpfCnpjSolicitante: string;
  readonly: boolean = false;
  ged: any;
  listaBic: any;
  listaComplemento: any;
  listaClassificacao: any;
  listaRegimeRecolhimento: any;
  listaNaturezaJuridica: any;
  listaCnae: any = [];
  listaOcupacao: any;
  listaPaises: any;
  listaGed: any;
  dataAtual: any = new Date();
  loading: boolean = false;
  loadingReceita: boolean = false;
  loadingCrc: boolean = false;
  calendario: boolean = false;
  datePickerConfig: IDatePickerConfig = {
    disableKeypress: true,
    drops: "down",
    format: 'DD/MM/YYYY'
  }
  dataInicioProdutorRural: Date = null;
  dataFimProdutorRural: Date = null;
  dataAquisicao: Date = null;
  dataRegistro: Date = null;
  dataInicioSocio: Date = null;
  dataFimSocio: Date = null;
  cnaeAberto: boolean = false;
  cnaePrincipal: boolean = false;
  cnae = [];
  cnaeCounter: number = 0;
  nomeCnae: string;
  codigo = 0
  sociosAberto: boolean = false;
  socios = [];
  pais: number = 30;
  sociosCounter: number = 0;
  participacaoCounter: number = 0;
  liberarAdicao: boolean = true;
  motivo: number = 1;
  cpfContrubuinte: string;
  mesmoEndereco: boolean = false;
  enderecoCorrespondencia: any;
  car: string;
  carAberto: boolean = false;
  arquivos: any = [];
  arquivoValido: boolean = true;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private procuracaoService: ProcuracaoService,
    private datePipe: DatePipe,
    private contribuinteService: ContribuinteService,
    private produtorRuralService: ProdutorRuralService,
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef,
    private usuarioLogadoSerivice: UsuarioLogadoService
  ) {
    dayjs.locale('pt-br');
    enableProdMode();
  }

  ngOnInit(): void {
    setTimeout(() => {      
      this.usuarioLogado = this.usuarioLogadoSerivice.getUsuarioLogado();
      this.solicitante = this.usuarioLogado.dscRazaoSocial;
      this.cpfCnpjSolicitante = this.usuarioLogado.cpfCnpj;
    }, 500);
    this.getBic();
    this.getListaComplemento();
    this.criarFormulario();
    this.getListaClassificacao();
    this.getListaRegimeRecolhimento();
    this.getListaNaturezaJuridica();
    this.getListaCnae();
    this.getListaOcupacao();
    this.getListaPaises();
    this.getListaGed();
    this.dataAtual.setHours(0, 0, 0);
  }  

  criarFormulario() {
    this.produtorRuralForm = this.formBuilder.group({
      tipoContribuinte: [{value: 1, disabled: true}, Validators.required],
      inscricaoCpfCnpj: ['', Validators.required],
      nomeRazaoSocial: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      dataInicio: ['', Validators.required],
      dataTermino: [{value: null, disabled: true}]
    });

    this.enderecoPropriedadeForm = this.formBuilder.group({
      cep: ['', Validators.required],
      logradouro: ['', Validators.required],
      municipio: ['', Validators.required],
      estado: ['', Validators.required],
      numero: ['', Validators.required],
      tipoComplemento: [{value: 35, disabled: true}],
      complemento: [null],
      localizacao: [{ value: '2', disabled: true}, Validators.required],
      latitude: [null, [Validators.min(-90), Validators.max(90)]],
      longitude: [null, [Validators.min(-180), Validators.max(180)]]
    });

    this.enderecoCorrespondenciaForm = this.formBuilder.group({
      mesmoEndereco: [false],
      cep: ['', Validators.required],
      logradouro: ['', Validators.required],
      municipio: ['', Validators.required],
      estado: ['', Validators.required],
      numero: ['', Validators.required],
      tipoComplemento: [null],
      complemento: [null],
      localizacao: ['2', Validators.required],
      latitude: [null, [Validators.min(-90), Validators.max(90)]],
      longitude: [null, [Validators.min(-180), Validators.max(180)]]
    });

    this.informacoesEconomicasForm = this.formBuilder.group({
      classificacaoContribuinte: [{value: 3, disabled: 'true'}, Validators.required],
      protocoloRegistro: [{value: null, disabled: true}],
      dataRegistro: [{value: null, disabled: true}],
      capitalSocial: ['', [Validators.required, Validators.min(0.01)]],
      regimeRecolhimento: [{value: 0, disabled: true}],
      naturezaJuridica: [{value: 89, disabled: true}]
    });

    this.cnaeForm = this.formBuilder.group({
      cnae: ['', Validators.required],
      atividadePrincipal: [null, Validators.required],
      exercidoEndereco: [null, Validators.required]
    });
  
    this.informacoesExploracaoForm = this.formBuilder.group({
      categoriaOcupacao: ['', Validators.required],
      dataAquisicao: ['', Validators.required],
      inscricaoIncra: ['', Validators.required],
      nomeImovel: ['', Validators.compose([Validators.required, Validators.minLength(2)])],
      areaImovel: ['', Validators.required],
      areaPastagemNatural: [null],
      areaPastagemFormada: [null],
      areaInexplorada: [null],
      areaCultivada: [null],
      valorEstimadoImovel: ['', [Validators.required, Validators.min(0.01)]],
      observacao: [null]
    });

    this.contabilistaForm = this.formBuilder.group({
      cpf: [''],
      nome: [''],
      crc: [''],
      email: ['']
    });

    this.sociosForm = this.formBuilder.group({
      pais: ['', Validators.required],
      tipoDocumento: ['', Validators.required],
      documento: ['', Validators.required],
      cpfCnpj: ['', Validators.required],
      nome: ['', [Validators.required, Validators.minLength(2)]],
      participacao: ['', Validators.required],
      tipoPessoa: ['', Validators.required],
      administrador: ['', Validators.required],
      dataEntrada: ['', Validators.required],
      dataSaida: [{value: null, disabled: true}],
      enderecoCompleto: [null],
      cep: [null, Validators.required],
      logradouro: [null],
      numero: [null, Validators.required],
      tipoComplemento: [null],
      complemento: [null],
      latitude: [null, [Validators.min(-90), Validators.max(90)]],
      longitude: [null, [Validators.min(-180), Validators.max(180)]]
    });

  }

  getBic() {
    this.loading = true;
    this.contribuinteService.getDadosListaBic().subscribe(
      res => { 
        this.listaBic = res;
      },
      err => {

      },
      () => {
        this.loading = false;
      }
    )
  }

  getListaComplemento() {
    this.produtorRuralService.getListaComplementos().subscribe(
      res => {
        this.listaComplemento = res;
      }
    )
  }

  getListaClassificacao() {
    this.produtorRuralService.getListaClassificacao().subscribe(
      res => {
        this.listaClassificacao = res;
      }
    )
  }

  getListaRegimeRecolhimento() {
    this.produtorRuralService.getListaRegimeRecolhimento().subscribe(
      res => {
        this.listaRegimeRecolhimento = res;
      }
    )
  }

  getListaNaturezaJuridica() {
    this.produtorRuralService.getListaNaturezaJuridica().subscribe(
      res => {
        this.listaNaturezaJuridica = res;
      }
    )
  }

  getListaCnae() {
    let params: {
      filtrar: 'true'
    }
    this.produtorRuralService.getListaCnae(params).subscribe(
      res => {
        this.listaCnae = res;
      }
    )
  }

  getNomeCnae() {
    let id = this.listaCnae[this.cnaeForm.controls.cnae.value].id;
    for (let i = 0; i < this.listaCnae.length; i++) {
      if (this.listaCnae[i].id == id) {
        this.nomeCnae = this.listaCnae[i].dscCnae;
      }
    }
  }

  getListaOcupacao() {
    this.produtorRuralService.getListaOcupacao().subscribe(
      res => {
        this.listaOcupacao = res;
      }
    )
  }

  getListaPaises() {
    this.produtorRuralService.getListaPaises().subscribe(
      res => {
        this.listaPaises = res;
      }
    )
  }

  getListaGed() {
    this.produtorRuralService.getListaGedIdentificador().subscribe(
      res => {
        this.listaGed = res;
      }
    )
  }

  proximoPasso() {
    if (this.passo == 1) {
      this.active1 = false;
      this.active2 = true;
      this.passo++;
    } else if (this.passo == 2) {
      this.active2 = false;
      this.active3 = true;
      this.passo++;
    } else if (this.passo == 3) {
      this.active3 = false;
      this.active4 = true;
      this.passo++;
    } else if (this.passo == 4) {
      this.active4 = false;
      this.active5 = true;
      this.passo++;
    } else if (this.passo == 5) {
      this.active5 = false;
      this.active6 = true;
      this.passo++;
    } else if (this.passo == 6) {
      let soma = this.informacoesExploracaoForm.controls.areaPastagemNatural.value +
        this.informacoesExploracaoForm.controls.areaPastagemFormada.value +
        this.informacoesExploracaoForm.controls.areaInexplorada.value +
        this.informacoesExploracaoForm.controls.areaCultivada.value;
      if (this.informacoesExploracaoForm.controls.areaImovel.value < soma) {
        this.informacoesExploracaoForm.controls.areaImovel.setErrors({'soma': true});
      } else {
        this.active6 = false;
        this.active7 = true;
        this.passo++;
      }
    } else if (this.passo == 7) {
      this.active7 = false;
      this.active8 = true;
      this.passo++;
      if (this.informacoesExploracaoForm.controls.categoriaOcupacao.value == 1) {
        this.sociosForm.controls.tipoPessoa.setValue('1');
        this.sociosForm.controls.participacao.setValue(100);
        this.sociosForm.controls.tipoPessoa.disable();
        this.sociosForm.controls.participacao.disable();
      } else {
        this.sociosForm.controls.tipoPessoa.setValue('');
        this.sociosForm.controls.participacao.setValue('');
        this.sociosForm.controls.tipoPessoa.enable();
        this.sociosForm.controls.participacao.enable();
      }
    } else if (this.passo == 8) {
      this.active8 = false;
      this.active9 = true;
      this.passo++;
    }
  }

  voltarPasso() {
    if (this.passo == 2) {
      this.active2 = false;
      this.active1 = true;
      this.passo--;
    } else if (this.passo == 3) {
      this.active3 = false;
      this.active2 = true;
      this.passo--;
    } else if (this.passo == 4) {
      this.active4 = false;
      this.active3 = true;
      this.passo--;
    } else if (this.passo == 5) {
      this.active5 = false;
      this.active4 = true;
      this.passo--;
    } else if (this.passo == 6) {
      this.active6 = false;
      this.active5 = true;
      this.passo--;
    } else if (this.passo == 7) {
      this.active7 = false;
      this.active6 = true;
      this.passo--;
    } else if (this.passo == 8) {
      this.active8 = false;
      this.active7 = true;
      this.passo--;
    } else if (this.passo == 9) {
      this.active9 = false;
      this.active8 = true;
      this.passo--;
    }
  }

  desistir() {
    Swal.fire({
      icon: 'question',
      text: 'Atenção! Desistir neste momento implica em descartar todos os dados digitados. Confirma?',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não'
    }).then(result => {
      if (result.isConfirmed) {
        this.router.navigate(['cadastro/produtor-rural/inscricao']);
      } else if (result.isDenied) {
        Swal.close();
      }
    })
  }

  validarTipoPessoa() {
    if (this.produtorRuralForm.controls.tipoContribuinte.value == 1) {
      this.produtorRuralForm.controls.inscricaoCpfCnpj.setValidators(Validators.compose([Validators.minLength(11), Validators.maxLength(11)]));
      this.produtorRuralForm.controls.inscricaoCpfCnpj.updateValueAndValidity();
    } else {
      this.produtorRuralForm.controls.inscricaoCpfCnpj.setValidators(Validators.compose([Validators.minLength(14)]));
      this.produtorRuralForm.controls.inscricaoCpfCnpj.updateValueAndValidity();
    }
  }

  cpfCnpjMask(cpfCnpj) {
    if (cpfCnpj.length == 11) {
      return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
    } else {
      return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
    }
  }

  verificarExistenciaCpfCnpj(cpf, form) {
    if (cpf.length == 11 && form == 'contabilista' && !this.contabilistaForm.controls.cpf.invalid) {
      this.loadingReceita = true;
      this.produtorRuralService.consultarCpfReceita(cpf).subscribe(
        res => {
          this.loadingReceita = false;
        },
        err => {
          this.loadingReceita = false;
          Swal.fire({
            icon: 'warning',
            text: 'Análise junto a Receita Federal não foi possível. Processando junto a base SEFAZ TO aguarde...',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.procuracaoService.getCpf(cpf).subscribe(
                res => {
                  let data: any = res
                  if (data.length != 0) {
                    let data: any = res;
                    let cpf = this.cpfCnpjMask(data[0].cpfCnpj);
                    Swal.fire({
                      icon: 'info',
                      html: `
                        <p>Atenção: Foi encontrado o C.P.F. com os dados informados na SEFAZ TO.</p>
                        <p>C.P.F: ${cpf}</p>
                        <p>Nome: ${data[0].dscNomeCadastro}</p>
                      `,
                      confirmButtonText: 'Utilizar os dados encontrados',
                      showCancelButton: true,
                      cancelButtonText: 'Estes dados estão inconsistentes, não utilizar',
                      allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.loading = false;
                        this.contabilistaForm.controls.nome.setValue(data[0].dscNomeCadastro);
                        this.contabilistaForm.controls.nome.disable();
                        this.contabilistaForm.controls.nome.addValidators(Validators.required);
                        this.contabilistaForm.controls.crc.addValidators(Validators.required);
                        this.contabilistaForm.controls.email.addValidators([Validators.required, Validators.email]);
  
                        this.contabilistaForm.controls.nome.updateValueAndValidity();
                        this.contabilistaForm.controls.crc.updateValueAndValidity();
                        this.contabilistaForm.controls.email.updateValueAndValidity();
                      } else if (result.isDismissed) {
                        this.contabilistaForm.controls.nome.addValidators(Validators.required);
                        this.contabilistaForm.controls.crc.addValidators(Validators.required);
                        this.contabilistaForm.controls.email.addValidators([Validators.required, Validators.email]);
  
                        this.contabilistaForm.controls.nome.updateValueAndValidity();
                        this.contabilistaForm.controls.crc.updateValueAndValidity();
                        this.contabilistaForm.controls.email.updateValueAndValidity();
                        Swal.fire({
                          icon: 'info',
                          text: `Atenção: Será permitida a digitação dos dados que
                            serão validados posteriormente pela Auditoria Fiscal.`
                        })
                      }
                    })
                  }
                },
                err => {
                  Swal.fire({
                    icon: 'error',
                    text: 'Ocorreu um erro ao validar os dados, tente novamente.'
                  })
                }
              )
            }
          })
        }
      )
    } else if (cpf.length == 11 && form == 'produtorRural' && !this.produtorRuralForm.controls.inscricaoCpfCnpj.invalid) {
      this.loadingReceita = true;
      this.produtorRuralService.consultarCpfReceita(cpf).subscribe(
        res => {
          this.loadingReceita = false;
        },
        err => {
          this.loadingReceita = false;
          Swal.fire({
            icon: 'warning',
            text: 'Análise junto a Receita Federal não foi possível. Processando junto a base SEFAZ TO aguarde...',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.procuracaoService.getCpf(cpf).subscribe(
                res => {
                  let data: any = res
                  if (data.length != 0) {
                    let data: any = res;
                    let cpf = this.cpfCnpjMask(data[0].cpfCnpj);
                    Swal.fire({
                      icon: 'info',
                      html: `
                        <p>Atenção: Foi encontrado o C.P.F. com os dados informados na SEFAZ TO.</p>
                        <p>C.P.F: ${cpf}</p>
                        <p>Nome: ${data[0].dscNomeCadastro}</p>
                      `,
                      confirmButtonText: 'Utilizar os dados encontrados',
                      showCancelButton: true,
                      cancelButtonText: 'Estes dados estão inconsistentes, não utilizar',
                      allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.loading = false;
                        this.produtorRuralForm.controls.nomeRazaoSocial.setValue(data[0].dscNomeCadastro);
                        this.produtorRuralForm.controls.nomeRazaoSocial.disable();
                      } else if (result.isDismissed) {
                        Swal.fire({
                          icon: 'info',
                          text: `Atenção: Será permitida a digitação dos dados que
                            serão validados posteriormente pela Auditoria Fiscal.`
                        })
                      }
                    })
                  }
                },
                err => {
                  Swal.fire({
                    icon: 'error',
                    text: 'Ocorreu um erro ao validar os dados, tente novamente.'
                  })
                }
              )
            }
          })
        }
      )
    } else if ((cpf.length == 11 || cpf.length == 14) && form == 'socios' && !this.sociosForm.controls.cpfCnpj.invalid) {
      this.loadingReceita = true;
      this.produtorRuralService.consultarCpfReceita(cpf).subscribe(
        res => {
          this.loadingReceita = false;
        },
        err => {
          this.loadingReceita = false;
          Swal.fire({
            icon: 'warning',
            text: 'Análise junto a Receita Federal não foi possível. Processando junto a base SEFAZ TO aguarde...',
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              this.procuracaoService.getCpf(cpf).subscribe(
                res => {
                  let data: any = res
                  if (data.length != 0) {
                    let data: any = res;
                    let cpf = this.cpfCnpjMask(data[0].cpfCnpj);
                    Swal.fire({
                      icon: 'info',
                      html: `
                        <p>Atenção: Foi encontrado o C.P.F. com os dados informados na SEFAZ TO.</p>
                        <p>C.P.F: ${cpf}</p>
                        <p>Nome: ${data[0].dscNomeCadastro}</p>
                      `,
                      confirmButtonText: 'Utilizar os dados encontrados',
                      showCancelButton: true,
                      cancelButtonText: 'Estes dados estão inconsistentes, não utilizar',
                      allowOutsideClick: false
                    }).then((result) => {
                      if (result.isConfirmed) {
                        this.loading = false;
                        this.sociosForm.controls.nome.setValue(data[0].dscNomeCadastro);
                        this.sociosForm.controls.nome.disable();
                      } else if (result.isDismissed) {
                        Swal.fire({
                          icon: 'info',
                          text: `Atenção: Será permitida a digitação dos dados que
                            serão validados posteriormente pela Auditoria Fiscal.`
                        })
                      }
                    })
                  }
                },
                err => {
                  Swal.fire({
                    icon: 'error',
                    text: 'Ocorreu um erro ao validar os dados, tente novamente.'
                  })
                }
              )
            }
          })
        }
      )
    }
  }

  enderecoAlterado() {
    this.enderecoCorrespondenciaForm.controls.mesmoEndereco.setValue(false);
    this.enderecoCorrespondenciaForm.reset();
    this.enderecoCorrespondenciaForm.controls.localizacao.setValue('2');
    this.enderecoCorrespondenciaForm.controls.cep.enable();
    this.enderecoCorrespondenciaForm.controls.logradouro.enable();
    this.enderecoCorrespondenciaForm.controls.municipio.enable();
    this.enderecoCorrespondenciaForm.controls.estado.enable();
    this.enderecoCorrespondenciaForm.controls.numero.enable();
    this.enderecoCorrespondenciaForm.controls.tipoComplemento.enable();
    this.enderecoCorrespondenciaForm.controls.complemento.enable();
    this.enderecoCorrespondenciaForm.controls.localizacao.enable();
    this.enderecoCorrespondenciaForm.controls.latitude.enable();
    this.enderecoCorrespondenciaForm.controls.longitude.enable();
  }

  validarTipoComplemento(form: string) {
    if (form == 'enderecoPropriedade') {
      if (this.enderecoPropriedadeForm.controls.tipoComplemento) {
        this.enderecoPropriedadeForm.controls.complemento.setValidators(Validators.required);
        this.enderecoPropriedadeForm.controls.complemento.updateValueAndValidity();
      }
    }
    if (form == 'enderecoCorrespondencia') {
      if (this.enderecoCorrespondenciaForm.controls.tipoComplemento) {
        this.enderecoCorrespondenciaForm.controls.complemento.setValidators(Validators.required);
        this.enderecoCorrespondenciaForm.controls.complemento.updateValueAndValidity();
      }
    }
    if(form == 'socios') {
      if (this.sociosForm.controls.tipoComplemento) {
        this.sociosForm.controls.complemento.setValidators(Validators.required);
        this.sociosForm.controls.complemento.updateValueAndValidity();
      }
    }
  }

  validarProtocolo() {
    if (this.informacoesEconomicasForm.controls.protocoloRegistro.value) {
      this.informacoesEconomicasForm.controls.dataRegistro.setValidators(Validators.required);
      this.informacoesEconomicasForm.controls.dataRegistro.updateValueAndValidity();
    }
  }

  validarDigitoCpfCnpj(form: string) {
    if (form == 'produtorRural') {
      if (cpf.isValid(this.produtorRuralForm.controls.inscricaoCpfCnpj.value) == false && this.produtorRuralForm.controls.inscricaoCpfCnpj.value.length == 11) {
        this.produtorRuralForm.controls.inscricaoCpfCnpj.setErrors({'invalid': true});
        this.produtorRuralForm.controls.nomeRazaoSocial.reset();
        this.produtorRuralForm.controls.nomeRazaoSocial.enable();
      } else if (this.produtorRuralForm.controls.inscricaoCpfCnpj.value.length > 0 && this.produtorRuralForm.controls.inscricaoCpfCnpj.value.length < 11) {
        this.produtorRuralForm.controls.inscricaoCpfCnpj.setErrors({'invalid': true});
        this.produtorRuralForm.controls.nomeRazaoSocial.reset();
        this.produtorRuralForm.controls.nomeRazaoSocial.enable();
      } else {
        this.produtorRuralForm.controls.inscricaoCpfCnpj.setErrors(null);
        this.produtorRuralForm.controls.nomeRazaoSocial.reset();
        this.produtorRuralForm.controls.nomeRazaoSocial.enable();
      }
      this.verificarExistenciaCpfCnpj(this.produtorRuralForm.controls.inscricaoCpfCnpj.value, 'produtorRural');
    }
    if (form == 'contabilista') {
      if (cpf.isValid(this.contabilistaForm.controls.cpf.value) == false && this.contabilistaForm.controls.cpf.value.length == 11) {
        this.contabilistaForm.controls.cpf.setErrors({'invalid': true});
        this.contabilistaForm.controls.nome.reset();
        this.contabilistaForm.controls.nome.enable();
      } else if (this.contabilistaForm.controls.cpf.value.length > 0 && this.contabilistaForm.controls.cpf.value.length < 11) {
        this.contabilistaForm.controls.cpf.setErrors({'invalid': true});
        this.contabilistaForm.controls.nome.reset();
        this.contabilistaForm.controls.nome.enable();
      } else {
        this.contabilistaForm.controls.cpf.setErrors(null);
        this.contabilistaForm.controls.nome.setValue('');
        this.contabilistaForm.controls.nome.enable();
        this.contabilistaForm.controls.nome.clearValidators();
        this.contabilistaForm.controls.crc.clearValidators();
        this.contabilistaForm.controls.email.clearValidators();
        this.contabilistaForm.controls.email.setValidators(Validators.email);

        this.contabilistaForm.controls.nome.updateValueAndValidity();
        this.contabilistaForm.controls.crc.updateValueAndValidity();
        this.contabilistaForm.controls.email.updateValueAndValidity();
        this.verificarExistenciaCpfCnpj(this.contabilistaForm.controls.cpf.value, 'contabilista');
      }
    }
    if (form == 'socios') {
      if (cpf.isValid(this.sociosForm.controls.cpfCnpj.value) == false && this.sociosForm.controls.cpfCnpj.value.length == 11) {
        this.sociosForm.controls.cpfCnpj.setErrors({'invalid': true});
        this.sociosForm.controls.nome.reset();
        this.sociosForm.controls.nome.enable();
      } else if (
        (this.sociosForm.controls.cpfCnpj.value.length > 0 && this.sociosForm.controls.cpfCnpj.value.length < 11
        || this.sociosForm.controls.cpfCnpj.value.length > 12 && this.sociosForm.controls.cpfCnpj.value.length < 14)
        ) {
          this.sociosForm.controls.cpfCnpj.setErrors({'invalid': true});
          this.sociosForm.controls.nome.reset();
          this.sociosForm.controls.nome.enable();
      } else if (cnpj.isValid(this.sociosForm.controls.cpfCnpj.value) == false && this.sociosForm.controls.cpfCnpj.value.length == 14) {
        this.sociosForm.controls.cpfCnpj.setErrors({'invalid': true});
        this.sociosForm.controls.nome.reset();
        this.sociosForm.controls.nome.enable();
      } else {
        this.sociosForm.controls.cpfCnpj.setErrors(null);
        this.sociosForm.controls.nome.setValue('');
        this.sociosForm.controls.nome.enable();
        this.verificarExistenciaCpfCnpj(this.sociosForm.controls.cpfCnpj.value, 'socios');
      }
    }
  }

  adicionarCnae() {
    if (this.cnaePrincipal == true && this.cnaeForm.controls.atividadePrincipal.value == 1) {
      Swal.fire({
        icon: 'warning',
        text: `Atenção: Já tem um CNAE na lista marcado como Primária. Para prosseguir,
          altere a anterior para Secundária e depois mude esta para Primária.`
      })
    } else {
      this.nomeCnae = '';
      let obj = {
        cnae: {
          id: this.listaCnae[this.cnaeForm.controls.cnae.value].id
        },
        numPrincipal: this.cnaeForm.controls.atividadePrincipal.value,
        numExerceNoEnd: this.cnaeForm.controls.exercidoEndereco.value,
        cnaeTexto: '',
        principalTexto: '',
        exercidoTexto: '',
        id: this.cnaeCounter
      }
      let index = this.listaCnae[this.cnaeForm.controls.cnae.value].id;
      for (let i = 0; i < this.listaCnae.length; i++) {
        if (index == this.listaCnae[i].id) {
          obj.cnaeTexto = this.listaCnae[i].dscCnae;
          break
        }
      }
  
      if (this.cnaeForm.controls.atividadePrincipal.value == 1) {
        obj.principalTexto = 'Sim'
      } else {
        obj.principalTexto = 'Não'
      }
  
      if (this.cnaeForm.controls.exercidoEndereco.value == 1) {
        obj.exercidoTexto = 'Sim'
      } else {
        obj.exercidoTexto = 'Não'
      }
      
      if (this.cnaeForm.controls.atividadePrincipal.value == 1) {
        this.cnaePrincipal = true;
      }
      this.cnae.push(obj);
      this.cnaeCounter ++;
      this.cnaeAberto = false;
      this.cnaeForm.reset();
    }
    
  }

  deletarCnae(item) {
    for (let i = 0; i < this.cnae.length; i++) {
      if (this.cnae[i].id == item.id) {
        this.cnae.splice(i, 1);
      }
      if (this.cnae['numPrincipal'] == 1) {
        this.cnaePrincipal = true;
      } else {
        this.cnaePrincipal = false;
      }
    }
    
  }

  adicionarSocio() {
    this.sociosForm.controls.cep.removeValidators(Validators.required);
    this.sociosForm.controls.numero.removeValidators(Validators.required);
    this.sociosForm.controls.enderecoCompleto.removeValidators(Validators.required);
    this.sociosForm.controls.complemento.removeValidators(Validators.required);
    let obj = {
      tipoGed: {
        codigo: this.sociosForm.controls.tipoDocumento.value
      },
      dscDocumento: this.sociosForm.controls.documento.value,
      dscCpfCnpj: this.sociosForm.controls.cpfCnpj.value,
      dscNomeSocio: this.sociosForm.controls.nome.value,
      participacao: `${this.sociosForm.controls.participacao.value}`,
      datInicio: dayjs(this.dataInicioSocio).format('YYYY-MM-DD'),
      datFim: this.dataFimSocio != null ? dayjs(this.dataFimSocio).format('YYYY/MM/DD') : null,
      administrador: this.sociosForm.controls.administrador.value,
      numTipoPessoa: this.sociosForm.controls.tipoPessoa.value,
      logradouro: this.sociosForm.controls.logradouro.value,
      numero: this.sociosForm.controls.numero.value,
      socioEndereco: {
        endereco: {
          dscNumero: this.sociosForm.controls.numero.value,
          dscComplemento: this.sociosForm.controls.complemento.value,
          dscEnderecoExterior: this.sociosForm.controls.enderecoCompleto.value,
          cep: {
            codCep: this.sociosForm.controls.cep.value
          },
          pais: {
            codigo: this.sociosForm.controls.pais.value
          },
          tipoComplemento: {
            codigo: null
          },
          latitude: this.sociosForm.controls.latitude.value,
          longitute: this.sociosForm.controls.longitude.value,
          naturezaImovel: {
            codigo: 2
          }
        },
        tipoEndereco: {
            codigo: 2
        }
      },
      id: this.sociosCounter,
      valueComplemento: this.sociosForm.controls.tipoComplemento.value
    }
    this.sociosForm.controls.tipoComplemento.value
      ? obj.socioEndereco.endereco.tipoComplemento.codigo = this.listaComplemento[this.sociosForm.controls.tipoComplemento.value].codigo
      : '';
    
    if (this.dataFimSocio != null) {
      obj.datFim = dayjs(this.dataFimSocio).format('YYYY-MM-DD')
    }

    this.participacaoCounter += this.sociosForm.controls.participacao.value;
    
    if (this.participacaoCounter > 100) {
      this.participacaoCounter -= this.sociosForm.controls.participacao.value;
      this.sociosForm.controls.participacao.setErrors({invalid: true});
      return
    }

    this.socios.push(obj);
    this.sociosAberto = false;
    this.sociosForm.reset();
    this.pais = 30;
    this.verificarPais();

    if (this.informacoesExploracaoForm.controls.categoriaOcupacao.value == 1 && this.socios.length == 1) {
      this.liberarAdicao = false;
    }

    if (this.participacaoCounter == 100) {
      this.liberarAdicao = false;
    }
  }

  deletarSocio(item) {
    for (let i = 0; i < this.socios.length; i++) {
      if (this.socios[i].id == item.id) {
        this.participacaoCounter -= this.socios[i].participacao;
        this.socios.splice(i, 1);
      }
    }

    if (this.participacaoCounter < 100) {
      this.liberarAdicao = true;
    }

    if (this.informacoesExploracaoForm.controls.categoriaOcupacao.value == 1 && this.socios.length == 0) {
      this.liberarAdicao = true;
    }

    if (this.informacoesExploracaoForm.controls.categoriaOcupacao.value == 1) {
      this.sociosForm.controls.tipoPessoa.setValue('1');
      this.sociosForm.controls.participacao.setValue(100);
      this.sociosForm.controls.tipoPessoa.disable();
      this.sociosForm.controls.participacao.disable();
    }
  }

  abrirModal(modal: string) {
    if (modal == 'cnae') {
      this.cnaeAberto = true;
    } else if (modal == 'socios') {
      this.sociosAberto = true;
      this.changeDetector.detectChanges();
      if (this.informacoesExploracaoForm.controls.categoriaOcupacao.value == 1) {
        this.renderer.addClass(this.tipoPessoaSocio.nativeElement, 'radio-disabled');
      } else {
        this.sociosForm.controls.participacao.enable();
      }
    } else if (modal == 'car') {
      this.carAberto = true;
    }
  }

  fecharModal(modal: string) {
    if (modal == 'cnae') {
      this.nomeCnae = '';
      this.cnaeAberto = false;
      this.cnaeForm.reset();
    } else if (modal == 'socios') {
      this.sociosAberto = false;
      this.sociosForm.reset();
      this.pais = 30;
      this.verificarPais();
      if (this.informacoesExploracaoForm.controls.categoriaOcupacao.value == 1) {
        this.sociosForm.controls.tipoPessoa.setValue('1');
        this.sociosForm.controls.participacao.setValue(100);
        this.sociosForm.controls.tipoPessoa.disable();
        this.sociosForm.controls.participacao.disable();
      }
    } else if (modal == 'car') {
      this.carAberto = false;
      this.car = '';
    }
  }

  getDataInicio(event, form: string) {
    if (form == 'produtorRural') {
      if (this.dataInicioProdutorRural && this.dataInicioProdutorRural >= this.dataAtual) {
        this.produtorRuralForm.controls.dataInicio.reset();
        this.produtorRuralForm.controls.dataTermino.reset();
        this.produtorRuralForm.controls.dataTermino.disable();
        Swal.fire({
          icon: 'warning',
          text: 'Data de Início não pode ser maior que a data atual.'
        });
      }
      if (this.dataInicioProdutorRural && this.dataInicioProdutorRural < this.dataAtual) {
        this.produtorRuralForm.controls.dataTermino.enable();
        this.dataInicioProdutorRural = event;
      }
    }

    if (form == 'informacoesEconomicas') {
      this.dataRegistro = event;
    }

    if (form == 'informacoesExploracao') {
      this.dataAquisicao = event;
      if (this.dataAquisicao && this.dataAquisicao >= this.dataAtual) {
        this.informacoesExploracaoForm.controls.dataAquisicao.reset();
        Swal.fire({
          icon: 'warning',
          text: 'Data de aquisição não pode ser maior que a data atual.'
        })
      }
    }

    if (form == 'socios') {
      if (this.dataInicioSocio && this.dataInicioSocio >= this.dataAtual) {
        this.sociosForm.controls.dataEntrada.reset();
        Swal.fire({
          icon: 'warning',
          text: 'Data da Entrada não pode ser maior que a data atual.'
        });
      }

      if (this.dataInicioSocio && this.dataInicioSocio < this.dataAtual) {
        this.dataInicioProdutorRural = event;
      }
    }
  }

  getDataFim(event, form: string) {
    
    if (form == 'produtorRural') {
      if (
        this.dataFimProdutorRural 
        && (this.dataFimProdutorRural <= this.dataInicioProdutorRural || this.dataFimProdutorRural >= this.dataAtual) 
      ) {
        this.produtorRuralForm.controls.dataTermino.reset();
        Swal.fire({
          icon: 'warning',
          text: 'Término da Atividade deve ser maior que a Data de Início e menor que a data atual.'
        })
      }
      this.dataFimProdutorRural = event;
      console.log(this.dataFimProdutorRural);
      
    }

    if (form == 'socios') {
      if (
        this.dataFimSocio
        && (this.dataFimSocio <= this.dataInicioSocio || this.dataFimSocio >= this.dataAtual)
      ) {
        this.sociosForm.controls.dataSaida.reset();
        Swal.fire({
          icon: 'warning',
          text: 'Data de Saída deve ser maior que a Data da Entrada e menor que a data atual.'
        })
      }

      if (this.dataFimSocio && this.informacoesExploracaoForm.controls.categoriaOcupacao.value != 1) {
        this.sociosForm.controls.participacao.setValue(0);
        this.sociosForm.controls.participacao.disable();
      }
      this.dataFimSocio = event;
    }
  }

  verificarPais() {
    if (this.pais !== 30) {
      this.sociosForm.controls.enderecoCompleto.addValidators(Validators.required);
      this.sociosForm.controls.cep.removeValidators(Validators.required);
      this.sociosForm.controls.numero.removeValidators(Validators.required);
      this.sociosForm.controls.cep.updateValueAndValidity();
      this.sociosForm.controls.numero.updateValueAndValidity();
      this.sociosForm.controls.enderecoCompleto.updateValueAndValidity();
    } else {
      this.sociosForm.controls.cep.addValidators(Validators.required);
      this.sociosForm.controls.numero.addValidators(Validators.required);
      this.sociosForm.controls.enderecoCompleto.removeValidators(Validators.required);
      this.sociosForm.controls.cep.updateValueAndValidity();
      this.sociosForm.controls.numero.updateValueAndValidity();
      this.sociosForm.controls.enderecoCompleto.updateValueAndValidity();
    }
  }

  validarCep(event: Event, form: string): void{
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    if (/^\d{2}[.-]?\d{3}[.-]?\d{3}$/.test(inputValue)){
      this.verificarCep(form);
    } else {
      if (form == 'enderecoPropriedade') {
        this.apagarEndereco('enderecoPropriedade');
      } else if (form == 'enderecoCorrespondencia') {
        this.apagarEndereco('enderecoCorrespondencia');
      } else if (form == 'socios') {
        this.apagarEndereco('socios');
      }
    }
  }

  verificarCep(form: string) {
    if (form == 'socios') {
      this.loading = true;
      this.produtorRuralService.consultarCep(this.sociosForm.controls.cep.value).subscribe(
        res => {
          this.sociosForm.controls.logradouro.setValue(res['dscLogradouro']);
          this.sociosForm.controls.logradouro.disable();
        },
        err => {
          Swal.fire({
            icon: 'error',
            text: 'Atenção: C.E.P não encontrado/válido. Informar um próximo ou genérico da cidade.'
          }).then(result => {
            if (result.isConfirmed) {
              this.sociosForm.controls.cep.reset();
              this.cepSocios.nativeElement.focus();
              this.apagarEndereco('socios');
            }
          });
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      )
    } else if (form == 'enderecoPropriedade') {
      this.loading = true;
      this.produtorRuralService.consultarCep(this.enderecoPropriedadeForm.controls.cep.value).subscribe(
        res => {
          this.enderecoCorrespondencia = res;
          this.enderecoPropriedadeForm.controls.logradouro.setValue(res['dscLogradouro']);
          this.enderecoPropriedadeForm.controls.municipio.setValue(res['cidade']['dscCidade']);
          this.enderecoPropriedadeForm.controls.estado.setValue(res['cidade']['estado']['codigo']);
          this.enderecoPropriedadeForm.controls.logradouro.disable();
          this.enderecoPropriedadeForm.controls.municipio.disable();
          this.enderecoPropriedadeForm.controls.estado.disable();
        },
        err => {
          Swal.fire({
            icon: 'error',
            text: 'Atenção: C.E.P não encontrado/válido. Informar um próximo ou genérico da cidade.'
          }).then(result => {
            if (result.isConfirmed) {
              this.enderecoPropriedadeForm.controls.cep.reset();
              this.cepEnderecoPropriedade.nativeElement.focus();
              this.apagarEndereco('enderecoPropriedade');
            }
          })
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      )
    } else if (form == 'enderecoCorrespondencia') {
      this.loading = true;
      this.produtorRuralService.consultarCep(this.enderecoCorrespondenciaForm.controls.cep.value).subscribe(
        res => {
          this.enderecoCorrespondenciaForm.controls.logradouro.setValue(res['dscLogradouro']);
          this.enderecoCorrespondenciaForm.controls.municipio.setValue(res['cidade']['dscCidade']);
          this.enderecoCorrespondenciaForm.controls.estado.setValue(res['cidade']['estado']['codigo']);
          this.enderecoCorrespondenciaForm.controls.logradouro.disable();
          this.enderecoCorrespondenciaForm.controls.municipio.disable();
          this.enderecoCorrespondenciaForm.controls.estado.disable();
        },
        err => {
          Swal.fire({
            icon: 'error',
            text: 'Atenção: C.E.P não encontrado/válido. Informar um próximo ou genérico da cidade.'
          }).then(result => {
            if (result.isConfirmed) {
              this.enderecoCorrespondenciaForm.controls.cep.reset();
              this.cepEnderecoCorrespondencia.nativeElement.focus();
              this.apagarEndereco('enderecoCorrespondencia');
            }
          })
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      )
    }
  }

  apagarEndereco(form) {
    if (form == 'enderecoPropriedade') {
      this.enderecoPropriedadeForm.controls.logradouro.reset();
      this.enderecoPropriedadeForm.controls.municipio.reset();
      this.enderecoPropriedadeForm.controls.estado.reset();
      this.enderecoPropriedadeForm.controls.logradouro.enable();
      this.enderecoPropriedadeForm.controls.municipio.enable();
      this.enderecoPropriedadeForm.controls.estado.enable();
    } else if (form == 'enderecoCorrespondencia') {
      this.enderecoCorrespondenciaForm.controls.logradouro.reset();
      this.enderecoCorrespondenciaForm.controls.municipio.reset();
      this.enderecoCorrespondenciaForm.controls.estado.reset();
      this.enderecoCorrespondenciaForm.controls.logradouro.enable();
      this.enderecoCorrespondenciaForm.controls.municipio.enable();
      this.enderecoCorrespondenciaForm.controls.estado.enable();
    } else if (form == 'socios') {
      this.sociosForm.controls.logradouro.reset();
      this.sociosForm.controls.logradouro.enable();
    }
  }

  copiarFormularios() {
    if (this.enderecoCorrespondenciaForm.controls.mesmoEndereco.value == true) {
      this.enderecoCorrespondenciaForm.controls.cep.setValue(this.enderecoPropriedadeForm.controls.cep.value);
      this.enderecoCorrespondenciaForm.controls.logradouro.setValue(this.enderecoPropriedadeForm.controls.logradouro.value);
      this.enderecoCorrespondenciaForm.controls.municipio.setValue(this.enderecoPropriedadeForm.controls.municipio.value);
      this.enderecoCorrespondenciaForm.controls.estado.setValue(this.enderecoPropriedadeForm.controls.estado.value);
      this.enderecoCorrespondenciaForm.controls.numero.setValue(this.enderecoPropriedadeForm.controls.numero.value);
      this.enderecoCorrespondenciaForm.controls.tipoComplemento.setValue(this.enderecoPropriedadeForm.controls.tipoComplemento.value);
      this.enderecoCorrespondenciaForm.controls.complemento.setValue(this.enderecoPropriedadeForm.controls.complemento.value);
      this.enderecoCorrespondenciaForm.controls.localizacao.setValue(this.enderecoPropriedadeForm.controls.localizacao.value);
      this.enderecoCorrespondenciaForm.controls.latitude.setValue(this.enderecoPropriedadeForm.controls.latitude.value);
      this.enderecoCorrespondenciaForm.controls.longitude.setValue(this.enderecoPropriedadeForm.controls.longitude.value);
      
      this.enderecoCorrespondenciaForm.controls.cep.disable();
      this.enderecoCorrespondenciaForm.controls.logradouro.disable();
      this.enderecoCorrespondenciaForm.controls.municipio.disable();
      this.enderecoCorrespondenciaForm.controls.estado.disable();
      this.enderecoCorrespondenciaForm.controls.numero.disable();
      this.enderecoCorrespondenciaForm.controls.tipoComplemento.disable();
      this.enderecoCorrespondenciaForm.controls.complemento.disable();
      this.enderecoCorrespondenciaForm.controls.localizacao.disable();
      this.enderecoCorrespondenciaForm.controls.latitude.disable();
      this.enderecoCorrespondenciaForm.controls.longitude.disable();
    } else {
      this.enderecoCorrespondenciaForm.reset();
      this.enderecoCorrespondenciaForm.controls.localizacao.setValue('2');
      this.enderecoCorrespondenciaForm.controls.cep.enable();
      this.enderecoCorrespondenciaForm.controls.logradouro.enable();
      this.enderecoCorrespondenciaForm.controls.municipio.enable();
      this.enderecoCorrespondenciaForm.controls.estado.enable();
      this.enderecoCorrespondenciaForm.controls.numero.enable();
      this.enderecoCorrespondenciaForm.controls.tipoComplemento.enable();
      this.enderecoCorrespondenciaForm.controls.complemento.enable();
      this.enderecoCorrespondenciaForm.controls.localizacao.enable();
      this.enderecoCorrespondenciaForm.controls.latitude.enable();
      this.enderecoCorrespondenciaForm.controls.longitude.enable();
    }
  }

  validarCategoriaOcupacao() {
    if (this.informacoesExploracaoForm.controls.categoriaOcupacao.value == 1) {
      this.abrirModal('car');
      this.socios = [];
      this.liberarAdicao = true;
      this.participacaoCounter = 0;
    }
  }

  buscarCar() {
    let data;
    this.produtorRuralService.getCar(this.car).subscribe(
      res => {
        // data = res;
        Swal.fire({
          icon: 'error',
          text: `Atenção: Não foi possível processar o CAR informado. Os dados abaixo poderão ser 
            informados e serão validados com o CAR pela Auditoria Fiscal.`
        })
      },
      err => {
        Swal.fire({
          icon: 'error',
          text: `Atenção: Não foi possível processar o CAR informado. Os dados abaixo poderão ser 
            informados e serão validados com o CAR pela Auditoria Fiscal.`
        })
      },
      () => {
        // Adicionar dados após validação do CAR

        // this.informacoesExploracaoForm.controls.inscricaoIncra.setValue(data.inscricaoIncra);
        // this.informacoesExploracaoForm.controls.nomeImovel.setValue(data.nomeImovel);
        // this.informacoesExploracaoForm.controls.areaImovel.setValue(data.areaImovel);
        // this.informacoesExploracaoForm.controls.areaPastagemNatural.setValue(data.areaPastagemNatural);
        // this.informacoesExploracaoForm.controls.areaPastagemFormada.setValue(data.areaPastagemFormada);
        // this.informacoesExploracaoForm.controls.areaInexplorada.setValue(data.areaInexplorada);
        // this.informacoesExploracaoForm.controls.areaCultivada.setValue(data.areaCultivada);

        // this.informacoesExploracaoForm.controls.inscricaoIncra.disable();
        // this.informacoesExploracaoForm.controls.nomeImovel.disable();
        // this.informacoesExploracaoForm.controls.areaImovel.disable();
        // this.informacoesExploracaoForm.controls.areaPastagemNatural.disable();
        // this.informacoesExploracaoForm.controls.areaPastagemFormada.disable();
        // this.informacoesExploracaoForm.controls.areaInexplorada.disable();
        // this.informacoesExploracaoForm.controls.areaCultivada.disable();
        this.fecharModal('car')
      }
    )
  }

  consultarCrc(crc) {
    this.loadingCrc = true;
    this.produtorRuralService.consultarCrc(crc)
      .pipe(
        timeout(5000),
        catchError(err => {
          this.loadingCrc = false;
          Swal.fire({
            icon: 'warning',
            text: `Atenção: Não foi possível validar os dados junto ao C.R.C. 
            Será Permitida a digitação dos dados que serão validados posteriormente pela Auditoria Fiscal.`
          })
          return err;
        })
      )
      .subscribe(
        res => {
          this.loadingCrc = false;
          Swal.fire({
            icon: 'warning',
            text: `Atenção: Não foi possível validar os dados junto ao C.R.C. 
              Será Permitida a digitação dos dados que serão validados posteriormente pela Auditoria Fiscal.`
          })
        }
      )
  }

  anexo(event) {
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].type == 'image/jpeg' || event.target.files[i].type == 'image/gif' || event.target.files[i].type == 'application/pdf') {
        this.arquivoValido = true;
        this.arquivos.push(event.target.files[i]);
      } else {
        this.arquivoValido = false;
        return
      }
    }
    
  }

  deletarArquivo(index) {
    this.arquivos.splice(index, 1);
  }

  cadastrarProdutorRural() {
    this.loading = true;
    let obj: CadastroProdutorRural = {
      cadastro: {
        cpfCnpj: this.produtorRuralForm.controls.inscricaoCpfCnpj.value,
        dscNomeCadastro: this.produtorRuralForm.controls.nomeRazaoSocial.value,
        tipoPessoa: this.produtorRuralForm.controls.tipoContribuinte.value,
      },
      bic: {
        cadastro: {
          codigo: this.usuarioLogado.codigoCadastro
        },
        listaBic: {
          id: this.motivo,
        },
      },
      contribuinte: {
        dscRazaoSocial: this.produtorRuralForm.controls.nomeRazaoSocial.value,
        datInicioAtiv: dayjs(this.dataInicioProdutorRural).format('YYYY-MM-DD'),
        datFimAtiv: this.dataFimProdutorRural != null ? dayjs(this.dataFimProdutorRural).format('YYYY-MM-DD') : null,
        vrlCapitalSocial: this.informacoesEconomicasForm.controls.capitalSocial.value,
        codCategEstabelecimento: 1,
        codClassificacao: this.informacoesEconomicasForm.controls.classificacaoContribuinte.value
      },
      contribuinteEndereco: {
        endereco: {
          cep: {
            codCep: this.enderecoCorrespondenciaForm.controls.cep.value
          },
          dscNumero: this.enderecoCorrespondenciaForm.controls.numero.value,
          dscComplemento: this.enderecoCorrespondenciaForm.controls.complemento.value,
          tipoComplemento: {
            codigo: null,
          },
          latitude: this.enderecoCorrespondenciaForm.controls.latitude.value,
          longitute: this.enderecoCorrespondenciaForm.controls.longitude.value,
          naturezaImovel: {
            codigo: this.enderecoCorrespondenciaForm.controls.localizacao.value,
          },
        },
      },
      informacoesFiscaisContribuinte: {
        capitalSocial: this.informacoesEconomicasForm.controls.capitalSocial.value,
        histRedeSimContribuinteDTO: {
          protocoloJuntaComercial: this.informacoesEconomicasForm.controls.protocoloRegistro.value,
          datOcorrencia: this.dataRegistro != null ? dayjs(this.dataRegistro).format('YYYY-MM-DD') : null,
        },
        contribuinteRegimeRecolhimentoDTO: {
          tipoRegimeRecolhimento: {
            id: this.informacoesEconomicasForm.controls.regimeRecolhimento.value,
          },
        },
        naturezaJuridicaContribuinteDTO: {
          naturezaJuridica: {
            id: this.informacoesEconomicasForm.controls.naturezaJuridica.value,
          },
        },
        cnaeContribuinteDTO: [],
      },
      imovelDTO: {
        observacao: this.informacoesExploracaoForm.controls.observacao.value,
        endereco: {
          dscNumero: this.enderecoPropriedadeForm.controls.numero.value,
          dscComplemento: this.enderecoPropriedadeForm.controls.complemento.value,
          cep: {
            codCep: this.enderecoPropriedadeForm.controls.cep.value,
          },
          tipoComplemento: {
            codigo: this.listaComplemento[this.enderecoPropriedadeForm.controls.tipoComplemento.value].codigo,
          },
          latitude: this.enderecoPropriedadeForm.controls.latitude.value,
          longitute: this.enderecoPropriedadeForm.controls.longitude.value,
        },
        vlrImovel: this.informacoesExploracaoForm.controls.valorEstimadoImovel.value,
        datAquisicao: dayjs(this.dataAquisicao).format('YYYY-MM-DD'),
        numAreaTerreno: this.informacoesExploracaoForm.controls.areaImovel.value,
        dscCartorio: 'Descrição Cartório',
        dscRegistroCartorio: 'DescricaoRegCartorio',
        codNaturezaImovel: 2,
        codTipoUsoImovel: this.informacoesExploracaoForm.controls.categoriaOcupacao.value,
        dscInscricao: this.informacoesExploracaoForm.controls.inscricaoIncra.value,
        dscNomeImovel: this.informacoesExploracaoForm.controls.nomeImovel.value,
        numAreaPastagemNatural: this.informacoesExploracaoForm.controls.areaPastagemNatural.value,
        numAreaPastagemFormada: this.informacoesExploracaoForm.controls.areaPastagemFormada.value,
        numAreaInexplorada: this.informacoesExploracaoForm.controls.areaInexplorada.value,
        numAreaCultivada: this.informacoesExploracaoForm.controls.areaCultivada.value,
        imoveisGed: []
      },
      contabilistaResponsavel: {
        dscDocumento: this.contabilistaForm.controls.crc.value,
        cadastro: {
          cpfCnpj: this.contabilistaForm.controls.cpf.value,
          dscNomeCadastro: this.contabilistaForm.controls.nome.value,
          emails: [
            {
              descricao: this.contabilistaForm.controls.email.value,
            },
          ],
        },
      },
      socioDTO: []
    };

    this.enderecoCorrespondenciaForm.controls.tipoComplemento.value
      ? obj.contribuinteEndereco.endereco.tipoComplemento.codigo = this.listaComplemento[this.enderecoCorrespondenciaForm.controls.tipoComplemento.value].codigo
      : '';

    for (let i = 0; i < this.cnae.length; i++) {
      delete this.cnae[i].cnaeTexto;
      delete this.cnae[i].principalTexto;
      delete this.cnae[i].exercidoTexto;
      delete this.cnae[i].id;
      obj.informacoesFiscaisContribuinte.cnaeContribuinteDTO.push(this.cnae[i]);
    }

    for (let i = 0; i < this.socios.length; i++) {
      delete this.socios[i].id;
      delete this.socios[i].tipoDocumento;
      delete this.socios[i].paisTexto;
      delete this.socios[i].logradouro;
      delete this.socios[i].numero;
      delete this.socios[i].valueComplemento;
      if (this.socios[i].datFim == null) {
        delete this.socios[i].datFim;
      }
      if (this.socios[i].socioEndereco.endereco.tipoComplemento.codigo == null) {
        delete this.socios[i].socioEndereco.endereco.tipoComplemento;
      }
      obj.socioDTO.push(this.socios[i]);
      if (this.socios[i].socioEndereco.endereco.pais.codigo != 30) {
        delete this.socios[i].socioEndereco.endereco.cep;
      }
    }

    if (this.arquivos.length > 0) {
      let base64 = [];
      for (let i = 0; i < this.arquivos.length; i++) {
        const reader = new FileReader();
        reader.readAsDataURL(this.arquivos[i]);
        reader.onload = () => {
          base64.push(reader.result);
          base64[i] = base64[i].split(',')[1];
          let arquivo = {
            ged: {
              nomeArquivo: this.arquivos[i].name.replace(/\s/g, ''),
              arquivo: base64[i]
            }
          }
          obj.imovelDTO.imoveisGed.push(arquivo);
        }
      }
    }

    if (obj.contribuinte.datFimAtiv == null) {
      delete obj.contribuinte.datFimAtiv;
    }
    if (obj.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.datOcorrencia == null) {
      delete obj.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.datOcorrencia;
    }
    if (obj.contribuinteEndereco.endereco.tipoComplemento.codigo == null) {
      delete obj.contribuinteEndereco.endereco.tipoComplemento;
    }
    if (obj.imovelDTO.endereco.tipoComplemento.codigo == null) {
      delete obj.imovelDTO.endereco.tipoComplemento;
    }
    if (obj.imovelDTO.numAreaPastagemNatural == null) {
      delete obj.imovelDTO.numAreaPastagemNatural;
    }
    if (obj.imovelDTO.numAreaPastagemFormada == null) {
      delete obj.imovelDTO.numAreaPastagemFormada;
    }
    if (obj.imovelDTO.numAreaCultivada == null) {
      delete obj.imovelDTO.numAreaCultivada;
    }
    if (obj.imovelDTO.numAreaInexplorada == null) {
      delete obj.imovelDTO.numAreaInexplorada;
    }
    if (obj.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO.protocoloJuntaComercial == null) {
      delete obj.informacoesFiscaisContribuinte.histRedeSimContribuinteDTO;
    }

    setTimeout(() => {
      this.produtorRuralService.cadastrarProdutor(obj).subscribe(
        res => {
          Swal.fire({
            icon: 'success',
            text: 'Dados enviados com sucesso. Acompanhe pela Caixa Postal Eletrônica do solicitante a homologação dos dados enviados.'
          });
        },
        err => {
          Swal.fire({
            icon: 'error',
            text: 'Ops! Ocorreu um erro ao cadastrar o Produtor Rural'
          });
          this.router.navigate(['cadastro/produtor-rural/inscricao']);
        },
        () => {
          this.loading = false;
          this.router.navigate(['cadastro/produtor-rural/inscricao']);
        }
      )
    }, 500);
  }

}