import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './features/home/home.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { I18nConfiguration } from './utils/i18n.config';
import { CommonAppModule } from './features/common/common.module';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PreAcessoComponent } from './features/pre-acesso/pre-acesso.component';
import { InicioComponent } from './features/inicio/inicio.component';
import { DesempenhoService } from './services/desempenho.service';
import { IntegracaoComponent } from './features/integracao/integracao.component';
import { CaixaPostalComponent } from './features/caixa-postal/caixa-postal.component';
import { CaixaPostalService } from './services/caixa-postal.service';
import { CaixaPostalComunicadoComponent } from './features/caixa-postal/caixa-postal-comunicado/caixa-postal-comunicado.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelecaoRepresentanteComponent } from './features/selecao-representante/selecao-representante.component';
import { NgxMaskModule } from 'ngx-mask';
import { ProcuracaoComponent } from './features/procuracao/procuracao.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { ProcuracaoOutorgarComponent } from './features/procuracao/procuracao-outorgar/procuracao-outorgar.component';
import { faTrash, faUpload , faSearch, faAngleDown, faCircleQuestion, faCheck, faXmark} from '@fortawesome/free-solid-svg-icons';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';

import { ContribuinteService } from 'src/app/services/contribuinte.service';
import { ProdutorRuralService } from './services/produtor-rural.service';
import { DuvidasFrequentesComponent } from './features/home/duvidas-frequentes/duvidas-frequentes.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { RequestInterceptor } from './features/core/request.interceptor';
import { CadastroModule } from './features/cadastro/cadastro.module';
import { PerfilContribuinteComponent } from './features/perfil-contribuinte/perfil-contribuinte.component';
import { MeuPerfilComponent } from './features/meu-perfil/meu-perfil.component';
import { CadastroService } from './services/cadastro.service';
import { DefinirTipoComunicadoComponent } from './features/caixa-postal/definir-tipo-comunicado/definir-tipo-comunicado.component';
import { EnviarComunicadoComponent } from './features/caixa-postal/enviar-comunicado/enviar-comunicado.component';
import { UsuarioLogadoService } from './services/usuarioLogado.service';
import { ModalNfaComponent } from './features/modal-nfa/modal-nfa.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "environment.i18nPath", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    PreAcessoComponent,
    InicioComponent,
    IntegracaoComponent,
    CaixaPostalComponent,
    CaixaPostalComunicadoComponent,
    SelecaoRepresentanteComponent,
    ProcuracaoComponent,
    ProcuracaoOutorgarComponent,
    DuvidasFrequentesComponent,
    PerfilContribuinteComponent,
    MeuPerfilComponent,
    DefinirTipoComunicadoComponent,
    EnviarComunicadoComponent,
    ModalNfaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    NgbModule,
    HomeModule,
    CadastroModule,
    FontAwesomeModule,
    CommonAppModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    DpDatePickerModule,
    PdfViewerModule,
    CurrencyMaskModule,
    DropdownModule,
    EditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule
  ],
  providers: [
    HttpClient,
    TranslateService,
    DesempenhoService,
    CaixaPostalService,
    I18nConfiguration,
    DatePipe,
    ContribuinteService,
    ProdutorRuralService,
    CadastroService,
    UsuarioLogadoService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faUpload,
      faTrash,
      faAngleDown,
      faSearch,
      faCircleQuestion,
      faCheck,
      faXmark
    )
  }
}

