import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class ProdutorRuralService {

  private apiUrl = environment.API_URL;

  constructor(private http: HttpClient) {

  }

  getListaComplementos() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarTipoComplementoEndereco`
    )
  }

  getListaClassificacao() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarClassificacaoContribuinte`
    )
  }

  getListaRegimeRecolhimento() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarTipoRegimeRecolhimento`
    )
  }

  getListaNaturezaJuridica() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarNaturezaJuridica`
    )
  }

  getListaCnae(params?) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarCnae`,
      { params: params }
    )
  }

  getListaOcupacao() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarTipoUsoImovel`
    )
  }

  getListaPaises() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/listarPaises`
    )
  }

  getListaGed() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/ged/listarTipoGeds`
    )
  }

  getCar(protocolo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/obterCar/${protocolo}`
    )
  }

  getListaGedIdentificador() {
    return this.http.get(
      `${this.apiUrl}/contribuinte/ged/listarTipoGedsIdentificador`
    )
  }

  consultarCep(cep) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/obterCep/${cep}`
    )
  }

  consultarCpfReceita(cpf) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/cadastro/receitaFederal/${cpf}`
    )
  }

  consultarCrc(crc) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/obterCrc/${crc}`
    )
  }

  cadastrarProdutor(obj) {
    return this.http.post(
      `${this.apiUrl}/contribuinte/produtorRural/`, obj
    )
  }

  alterarProdutor(obj) {
    return this.http.put(
      `${this.apiUrl}/contribuinte/produtorRural/`, obj
    )
  }

  getSolicitacoes(contribuinte, pagina, tamanhoPagina) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/solicitacao/buscarPorContribuinte?contribuinte=${contribuinte}&pagina=${pagina}&tamanhoPagina=${tamanhoPagina}`
    )
  }

  getMovimentacoes(codigo, pagina, tamanhoPagina) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/solicitacao/movimentacao?pagina=${pagina}&tamanhoPagina=${tamanhoPagina}&solicitacao=${codigo}`
    )
  }

  getSolicitacaoPorProtocolo(id) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/solicitacao/id/${id}`
    )
  }

  getProdutorRural(codigo) {
    return this.http.get(
      `${this.apiUrl}/contribuinte/produtorRural/${codigo}/3`
    )
  }
}